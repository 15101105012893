const SESSION_STORAGE_KEY = "DEVELOPERSESSION";
const DEVELOPER_STORAGE_KEY = "DEVELOPERLOGGED";

class DeveloperSession{
    static get(){
        try{
            let sessionSTRING = localStorage.getItem(SESSION_STORAGE_KEY);

            if(!sessionSTRING){
                throw new Error("Sem sessão aberta");
            }

            try{
                const session = JSON.parse(sessionSTRING);
                return session;
            }catch(err){
                console.log(err);
                throw new Error("Sem sessão aberta ou corrompida");
            }
        }catch(err){
            console.log(err);
            throw new Error("Sem sessão aberta ou corrompida");
        }
    }

    static async isLogged(){
        const sessionSTRING = localStorage.getItem(SESSION_STORAGE_KEY);
        const developerSTRING = localStorage.getItem(DEVELOPER_STORAGE_KEY);

        if(!sessionSTRING || !developerSTRING){
            return false;
        }

        const session = JSON.parse(sessionSTRING);
        const developer = JSON.parse(developerSTRING);

        if(session){
            const URL = `/login/check/${session.sid}?token=DF74748A72FEE776A37BD566BB3CB`;
            const response = await fetch(URL, {
                method: "GET",
                headers:{
                    "Content-Type": "application/json"
                }
            });

            if(!response.ok){
                throw new Error("Erro ao tentar autenticar");
            }

            const data = await response.json();

            if(data.error){
                console.log(data);
                return false;
            }

            if(data.data.session.DeveloperId != developer._id){
                console.log("Sessão diferente do Developer");
                return false;
            }

            return true;
        }

    }

}

export default DeveloperSession;
