import React, {Component} from 'react';
import { Link, Route, withRouter } from "react-router-dom";
import { Button, Dialog, InputGroup, FormGroup } from "@blueprintjs/core";

import "../css/Server/Adicionar.css";

import Server from '../../models/Server';

import Developer from '../../models/Developer';

import Notify from '../../entity/Notify';

class ServerAdicionar extends Component {

    constructor(props){
        super(props);

        this.state = {
            _id: this.props.objectid || "",
            nome: "",
            epresa: "",
            url: "",
            "dummy_env": "",
            "quantidadeRotas": 1,
            "valor": 0
        }

        this.handleClose = this.handleClose.bind(this);
        this.handleSaveForm = this.handleSaveForm.bind(this);
        this.handleChangeForm = this.handleChangeForm.bind(this);
    }

    componentDidMount(){
        const _id = this.state._id;
        if(_id){
            Server.findById(_id).then(dev => {
                this.setState(dev);
            });
        }

    }

    handleClose(evt){
        this.props.isOpen = false;
        if(this.props.onCloseDialog){
            this.props.onCloseDialog(this);
        }
        this.props.history.push('/servers');
    }

    async handleSaveForm(){
        try{
            let model;
            if(this.state._id){
                model = new Server(this.state);
                model = await model.update();
            } else {
                model = await Server.create(this.state);
                this.setState(model);
            }
            this.handleClose();
            Notify.success({message: 'Salvo com sucesso'});
        }catch(err){
            console.log(err);
            Notify.error({message: err.message});
        }

    }

    handleChangeForm(evt){
        const nameInput = evt.target.getAttribute("name");
        let formDataChange = {};
        formDataChange[nameInput] = evt.target.value;
        this.setState(formDataChange);
    }

    render() {
        return (
            <Dialog {...this.props}>
                <div>
                    <Button minimal icon="cross" onClick={this.handleClose} />
                </div>
                <div class="form-group">
                    <FormGroup label="Nome" labelFor="nome-servidor">
                        <InputGroup
                           large
                           placeholder="Heroku name"
                           id="nome-servidor"
                           value={this.state.nome}
                           name="nome"
                           onChange={this.handleChangeForm}
                        />
                    </FormGroup>

                    <FormGroup label="Empresa" labelFor="empresa-server">
                        <InputGroup
                            large
                            placeholder="Empresa para Login"
                            id="empresa-server"
                            value={this.state.empresa}
                            name="empresa"
                            onChange={this.handleChangeForm}
                        />
                    </FormGroup>

                    <FormGroup label="Quantidade de Rotas" labelFor="quantidadeRotas-server">
                        <InputGroup
                            large
                            placeholder="Quantidade de Rotas"
                            id="quantidadeRotas-server"
                            value={this.state.quantidadeRotas}
                            name="quantidadeRotas"
                            onChange={this.handleChangeForm}
                        />
                    </FormGroup>

                    <FormGroup label="Dummy_ENV" labelFor="dummy-server">
                        <InputGroup
                            large
                            placeholder="Variável de Ambiente"
                            id="dummy-server"
                            value={this.state['dummy_env']}
                            name="dummy_env"
                            onChange={this.handleChangeForm}
                        />
                    </FormGroup>

                    <FormGroup label="URL" labelFor="url-server">
                        <InputGroup
                            large
                            placeholder="URL do Heroku"
                            id="url-server"
                            value={this.state.url}
                            name="url"
                            onChange={this.handleChangeForm}
                        />
                    </FormGroup>

                    {
                        Developer.isAdmin() ?
                            <FormGroup label="Valor por Rota" labelFor="valor-server">
                                <InputGroup
                                    large
                                    placeholder="Valor de cada Rota"
                                    id="valor-server"
                                    value={this.state.valor}
                                    name="valor"
                                    onChange={this.handleChangeForm}
                                />
                            </FormGroup>
                        :
                        ""
                    }

                    <FormGroup label="Salvar" labelFor="salvar-button">
                        <Button onClick={this.handleSaveForm} id="salvar-button" icon="new-person" text="Salvar" intent="success" />
                    </FormGroup>

                </div>
            </Dialog>
        );
    }
}

export default withRouter(ServerAdicionar);
