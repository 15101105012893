import DeveloperSession from "./DeveloperSession";

async function normalizeFetch(promFetch){
    const response = await promFetch;

    if(!response.ok) throw new Error("Error when fetch the server");

    const data = await response.json();

    if(data.error){
        console.log(data);
        throw new Error(data.msg);
    }

    return data.data;
}

class ServiceModel {

    static normalizeURL(url){
        let sufix = "&";
        if(url.indexOf("?") == -1){
            sufix = "?";
        }

        const session = DeveloperSession.get();

        if(!session){
            throw new Error("Sem sessão aberta!");
        }

        return `${url}${sufix}sid=${session.sid}`;
    }

    static get modelName(){
        return "Model";
    }

    static get host(){
        return "/rest";
    }

    static get serviceURL(){
        return `${this.prototype.host || this.host}/${this.prototype.modelName||this.modelName}`;
    }

    constructor(data){
        for(let keyname in data){
            this[keyname] = data[keyname];
        }
    }

    async update(){
        if(!this._id) throw new Error("Need _id to update a model");
        const body = JSON.stringify(this);
        try{

            const prom = fetch(ServiceModel.normalizeURL(`${this.constructor.serviceURL}/${this._id}`), {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            });

            const dataResponse = await normalizeFetch(prom);

            for(let key in dataResponse){
                this[key] = dataResponse[key];
            }

            return this;

        }catch(err){
            console.log(err);
            throw err;
        }

    }

    async remove(){
        if(!this._id) throw new Error("Need _id to update a model");
        console.log(this);
        const body = JSON.stringify(this);
        try{

            const prom = fetch(ServiceModel.normalizeURL(`${this.constructor.serviceURL}/${this._id}`), {
                method: 'DELETE',
            });

            const dataResponse = await normalizeFetch(prom);

            return this._id;

        }catch(err){
            console.log(err);
            throw err;
        }
    }

    static async create(data){
        const Class = this;
        const body = JSON.stringify(data);
        try{

            const prom = fetch(ServiceModel.normalizeURL(this.serviceURL), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            });

            const dataResponse = await normalizeFetch(prom);

            const model = new Class(dataResponse);
            return model;

        }catch(err){
            console.log(err);
            throw err;
        }
    }

    static async findById(id){
        const Class = this;
        let URL = this.serviceURL;
        try{

            const prom = fetch(ServiceModel.normalizeURL(`${URL}/${id}`), {
                method: 'GET'
            });

            const dataResponse = await normalizeFetch(prom);

            let dataModel = new Class(dataResponse);
            return dataModel;
        }catch(err){
            console.log(err);
            throw err;
        }
    }

    static async findAll(filter){
        const Class = this;
        let URL = this.serviceURL;
        if(filter){
            const filterString = JSON.stringify(filter);
            URL += `?filter=${filterString}`;
        }

        try{

            const prom = fetch(ServiceModel.normalizeURL(`${URL}`), {
                method: 'GET'
            });

            const dataResponse = await normalizeFetch(prom);

            let arrayData = dataResponse.map(m => new Class(m));
            return arrayData;

        }catch(err){
            console.log(err);
            throw err;
        }
    }

    static async findOne(){

    }


}


export default ServiceModel;
