import React, {Component} from 'react';
import { NavLink } from "react-router-dom";

import { Icon, Intent, Divider } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import "./css/MenuPrincipal.css"

class MenuPrincipal extends Component {

    render() {
        return (
            <ul className="Menu">
                <NavLink to="/dashboard">
                    <li class="Item">
                        <Icon icon={IconNames.HOME} iconSize={Icon.SIZE_LARGE} intent={Intent.PRIMARY} />
                        <span class="item-text bp3-button-text">
                            Dashboard
                        </span>
                    </li>
                </NavLink>
                <Divider className="Divisor" vertical />
                <NavLink to="/devs" >
                    <li className="Item">
                        <Icon icon={IconNames.PEOPLE} iconSize={Icon.SIZE_LARGE} intent={Intent.PRIMARY} />
                        <span class="item-text bp3-button-text">
                            Devs
                        </span>
                    </li>
                </NavLink>
                <NavLink to="/servers" >
                    <li className="Item">
                        <Icon icon={IconNames.CONSOLE} iconSize={Icon.SIZE_LARGE} intent={Intent.PRIMARY} />
                        <span class="item-text bp3-button-text">Servers</span>
                    </li>
                </NavLink>
                <NavLink to="/logs" >
                    <li className="Item">
                        <Icon icon={IconNames.SAVED} iconSize={Icon.SIZE_LARGE} intent={Intent.PRIMARY} />
                        <span class="item-text bp3-button-text">Logs</span>
                    </li>
                </NavLink>
                <li className="Item">
                    <Icon icon={IconNames.SEND_TO_GRAPH} iconSize={Icon.SIZE_LARGE} intent={Intent.PRIMARY} />
                    <span class="item-text bp3-button-text">Remote Execution</span>
                </li>
                <li className="Item">
                    <Icon icon={IconNames.DATABASE} iconSize={Icon.SIZE_LARGE} intent={Intent.PRIMARY} />
                    <span class="item-text bp3-button-text">Backups</span>
                </li>
                <li className="Item">
                    <Icon icon={IconNames.GIT_PUSH} iconSize={Icon.SIZE_LARGE} intent={Intent.PRIMARY} />
                    <span class="item-text bp3-button-text">Deploys</span>
                </li>
            </ul>
        );
    }
}

export default MenuPrincipal;
