import React, {Component} from 'react';

import { HashRouter as Router, Route, Link, Redirect } from "react-router-dom";

import DeveloperSession from './entity/DeveloperSession';

import logo from './logo.svg';
import './App.css';

import '../node_modules/normalize.css/normalize.css';
import '../node_modules/@blueprintjs/core/lib/css/blueprint.css';
import '../node_modules/@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '../node_modules/@blueprintjs/select/lib/css/blueprint-select.css';

import Header from "./components/layout/Header";
import MenuPrincipal from "./components/layout/MenuPrincipal";

import Dashboard from "./pages/Dashboard";
import Developers from "./pages/Developers";
import ServerPage from "./pages/Server";
import LogsPage from "./pages/LogsPage"

class App extends Component {

    componentDidMount(){
        DeveloperSession.isLogged().then(isLogged => {
            if(!isLogged){
                window.location = "/login";
                return;
            }
        })

    }

    render() {
        return (
            <Router>
                <div className="App">
                    <header class="bp3-dark">
                        <Header />
                    </header>
                    <section>
                        <nav class="bp3-dark">
                            <MenuPrincipal />
                        </nav>
                        <main>
                            <Route strict exact path="/dashboard" component={Dashboard} />
                            <Route path="/devs" component={Developers} />
                            <Route path="/servers" component={ServerPage} />
                            <Route path="/logs" component={LogsPage} />
                        </main>
                    </section>

                </div>
            </Router>
        );
    }
}

export default App;
