import React, {Component} from 'react';
import { Link, Route, withRouter } from "react-router-dom";
import { Button, Dialog, InputGroup, FormGroup } from "@blueprintjs/core";

import "../css/Developers/Adicionar.css";

import Developer from '../../models/Developer';

import Notify from '../../entity/Notify';

class DevelopersAdicionar extends Component {

    constructor(props){
        super(props);

        this.state = {
            _id: this.props.objectid || "",
            nome: "",
            login: "",
            senha: "",
            email: ""
        }

        this.handleClose = this.handleClose.bind(this);
        this.handleSaveForm = this.handleSaveForm.bind(this);
        this.handleChangeForm = this.handleChangeForm.bind(this);
    }

    componentDidMount(){
        const _id = this.state._id;
        if(_id){
            Developer.findById(_id).then(dev => {
                this.setState(dev);
            });
        }

    }

    handleClose(evt){
        this.props.isOpen = false;
        if(this.props.onCloseDialog){
            this.props.onCloseDialog(this);
        }
        this.props.history.push('/devs');
    }

    async handleSaveForm(){
        try{
            let model;
            if(this.state._id){
                model = new Developer(this.state);
                model = await model.update();
            } else {
                model = await Developer.create(this.state);
                this.setState(model);
            }
            this.handleClose();
            Notify.success({message: 'Salvo com sucesso'});
        }catch(err){
            console.log(err);
            Notify.error({message: err.message});
        }

    }

    handleChangeForm(evt){
        const nameInput = evt.target.getAttribute("name");
        let formDataChange = {};
        formDataChange[nameInput] = evt.target.value;
        this.setState(formDataChange);
    }

    render() {
        return (
            <Dialog {...this.props}>
                <div>
                    <Button minimal icon="cross" onClick={this.handleClose} />
                </div>
                <div class="form-group">
                    <FormGroup label="Nome" labelFor="nome-desenvolvedor">
                        <InputGroup
                           large
                           placeholder="Nome do Desenvolvedor"
                           id="nome-desenvolvedor"
                           value={this.state.nome}
                           name="nome"
                           onChange={this.handleChangeForm}
                        />
                    </FormGroup>

                    <FormGroup label="Login" labelFor="login-desenvolvedor">
                        <InputGroup
                            large
                            placeholder="Login do Desenvolvedor"
                            id="login-desenvolvedor"
                            value={this.state.login}
                            name="login"
                            onChange={this.handleChangeForm}
                        />
                    </FormGroup>

                    <FormGroup label="Senha" labelFor="senha-desenvolvedor">
                        <InputGroup
                            large
                            placeholder="Senha do Desenvolvedor"
                            id="senha-desenvolvedor"
                            type="password"
                            value={this.state.senha}
                            name="senha"
                            onChange={this.handleChangeForm}
                        />
                    </FormGroup>

                    <FormGroup label="E-mail" labelFor="email-desenvolvedor">
                        <InputGroup
                            large
                            placeholder="E-mail do Desenvolvedor"
                            id="email-desenvolvedor"
                            type="email"
                            value={this.state.email}
                            name="email"
                            onChange={this.handleChangeForm}
                        />
                    </FormGroup>

                    <FormGroup label="Salvar" labelFor="salvar-button">
                        <Button onClick={this.handleSaveForm} id="salvar-button" icon="new-person" text="Salvar" intent="success" />
                    </FormGroup>

                </div>
            </Dialog>
        );
    }
}

export default withRouter(DevelopersAdicionar);
