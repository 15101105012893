import ServiceModel from "../entity/ServiceModel";

class Developer extends ServiceModel{
    get modelName(){
        return "Developer";
    }

    static isAdmin(){

        if(localStorage.getItem('DEVELOPERLOGGED') && localStorage.getItem('DEVELOPERSESSION')){
            let developer = JSON.parse(localStorage.getItem('DEVELOPERLOGGED'));
            if(developer.login == "rzorzal" || developer.login == "wbueno"){
                return true;
            }
        }

        return false;
    }

}

export default Developer;
