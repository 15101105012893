import ServiceModel from "../entity/ServiceModel";

class Server extends ServiceModel{
    get modelName(){
        return "Server";
    }

    static async deployAll(){
        const URL = `/empresa/deploy/all?token=DF74748A72FEE776A37BD566BB3CB`;
        const response = await fetch(URL);

        if(!response.ok){
            throw new Error("Erro ao realizar fetch do deploy");
        }

        const data = await response.json();

        if(data.error){
            console.log(data);
            throw new Error(data.msg);
        }

        return data.data;

    }

    static async deploy(serverId){
        const URL = `/empresa/${serverId}/deploy?token=DF74748A72FEE776A37BD566BB3CB`;
        const response = await fetch(URL);

        if(!response.ok){
            throw new Error("Erro ao realizar fetch do deploy");
        }

        const data = await response.json();

        if(data.error){
            console.log(data);
            throw new Error(data.msg);
        }

        return data.data;

    }

    static async restart(serverId){
        const URL = `/empresa/${serverId}/restart?token=DF74748A72FEE776A37BD566BB3CB`;
        const response = await fetch(URL);

        if(!response.ok){
            throw new Error("Erro ao realizar fetch do restart");
        }

        const data = await response.json();

        if(data.error){
            console.log(data);
            throw new Error(data.msg);
        }

        return data.data;

    }

}

export default Server;
