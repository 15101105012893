import React, { Component } from 'react';
import { Link, Route } from "react-router-dom";

import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import "./css/Server.css";


import ServerAdicionar from "./Server/Adicionar";
import Server from '../models/Server';

import Developer from '../models/Developer';

import Notify from '../entity/Notify';

class ServerPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            servers: [],
            isAdicionarOpen: false,
            valorTotal: 0
        }

        this.onAdicionarClicked = this.onAdicionarClicked.bind(this);
        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    onAdicionarClicked() {
        this.setState({ isAdicionarOpen: true });
    }

    onCloseDialog() {
        this.refreshServers();
    }

    async onDelete(evt) {
        const id = evt.target.closest("[objectid]").getAttribute('objectid');
        let model = await Server.findById(id);
        await model.remove();
        await this.refreshServers();
        Notify.success({ message: "Removido com sucesso" });
    }

    async onDeployAllServers() {
        try {
            await Server.deployAll();
        } catch (err) {
            Notify.error(err);
        }
    }

    async onDeploy(evt) {
        try {
            const id = evt.target.closest("[objectid]").getAttribute('objectid');
            await Server.deploy(id);
        } catch (err) {
            Notify.error(err);
        }

    }

    async onRestart(evt) {
        try {
            const id = evt.target.closest("[objectid]").getAttribute('objectid');
            await Server.restart(id);
        } catch (err) {
            Notify.error(err);
        }

    }

    async refreshServers() {
        let servers = await Server.findAll();
        let valorTotal = servers.reduce((memo, server) => memo + (parseInt(server.valor) * parseInt(server.quantidadeRotas)), 0);
        this.setState({
            servers: servers,
            valorTotal
        });
    }

    componentDidMount() {
        this.refreshServers();
    }

    render() {
        return (
            <div class="container">
                <h1>Server</h1>
                <div class="page-buttons">
                    <Button onClick={this.onDeployAllServers} intent={Intent.WARNING} text="Deploy All Servers" icon={"automatic-updates"} />
                    <Link to="/servers/adicionar">
                        <Button onClick={this.onAdicionarClicked} intent={Intent.SUCCESS} text="Adicionar Server" icon={IconNames.ADD} />
                    </Link>
                </div>
                {Developer.isAdmin() ?
                    <h3>
                        Valor Total: {this.state.valorTotal}
                    </h3>
                    :
                    ""
                }
                <div class="table">
                    <table class="bp3-html-table bp3-condensed bp3-html-table-striped bp3-html-table-bordered bp3-interactive">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nome</th>
                                <th>Empresa</th>
                                <th>URL</th>
                                <th>Quantidade de Rotas</th>
                                <th>Criado</th>
                                {
                                    Developer.isAdmin() ?
                                        <th>Valor</th>
                                        :
                                        ""
                                }
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.servers.map(server =>
                                    <tr>
                                        <td>{server._id}</td>
                                        <td>{server.nome}</td>
                                        <td>{server.empresa}</td>
                                        <td>{server.url}</td>
                                        <td>{server.quantidadeRotas || 1}</td>
                                        <td>{server.processed ? "SIM" : "NÃO"}</td>
                                        {
                                            Developer.isAdmin() ?
                                                <td>{server.valor}</td>
                                                :
                                                ""
                                        }
                                        <td class="buttons">
                                            <Button objectid={server._id} onClick={this.onRestart} intent={Intent.DANGER} icon={"repeat"} />
                                            <Button objectid={server._id} onClick={this.onDeploy} intent={Intent.SUCCESS} icon={"automatic-updates"} />
                                            <Link to={`/servers/${server._id}/editar`}>
                                                <Button intent={Intent.SUCCESS} icon={IconNames.EDIT} />
                                            </Link>
                                            <Button objectid={server._id} onClick={this.onDelete} intent={Intent.DANGER} icon={IconNames.REMOVE} />
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>

                <Route path="/servers/adicionar" render={() => <ServerAdicionar onCloseDialog={this.onCloseDialog} isOpen={this.state.isAdicionarOpen} />} />
                <Route path="/servers/:id/editar" render={(props) => <ServerAdicionar onCloseDialog={this.onCloseDialog} objectid={props.match.params.id} isOpen />} />


            </div>);
    }
}

export default ServerPage;
