import React, {Component} from 'react';

import { Navbar, Button, Alignment } from "@blueprintjs/core";

class Header extends Component {


    render() {
        return (
            <Navbar>
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading>Máximus Soluções - Commander</Navbar.Heading>
                    <Navbar.Divider />
                    <Button className="bp3-minimal" icon="home" text="Dashborad" />
                    <Button className="bp3-minimal" icon="document" text="Operação" />
                </Navbar.Group>
            </Navbar>
        );
    }
}

export default Header;
