
import {Toaster} from '@blueprintjs/core';

class Notify{
    static success({message, timeout = 1501 }){
        return Toaster.create().show({
            message: message,
            timeout: timeout || 1501,
            intent: 'success',
            usePortal: true
        });
    }

    static warning({message, timeout = 1501 }){
        return Toaster.create().show({
            message: message,
            timeout: timeout || 1501,
            intent: 'warning',
            usePortal: true
        });
    }

    static error({message, timeout = 1501 }){
        return Toaster.create().show({
            message: message,
            timeout: timeout || 1501,
            intent: 'danger',
            usePortal: true
        });
    }

    static info({message, timeout = 1501 }){
        return Toaster.create().show({
            message: message,
            timeout: timeout || 1501,
            intent: 'primary',
            usePortal: true
        });
    }
}

export default Notify;
