import React, {Component} from 'react';
import { DateRangeInput } from "@blueprintjs/datetime";
import { FormGroup } from "@blueprintjs/core";
import moment from 'moment';

import './css/LogsPage.css';

import Server from '../models/Server';


class LogsPage extends Component {

    constructor(props){
        super(props);

        this.tipos = [
            "LOG",
            "ERROR",
            "WARNING"
        ]

        this.state = {
            startDate: moment().subtract('1','day').toDate(),
            endDate: moment().toDate(),
            empresa: null,
            tipo: null,
            servers: []
        }
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    async refreshServers(){
        console.log(Server);
        let servers = await Server.findAll();
        this.setState({
            servers: servers
        });
    }

    componentDidMount(){
        this.refreshServers();
    }

    handleSelectChange(evt){
        let stateKey = evt.target.name;
        let stateValue = evt.target.value;

        this.setState({[stateKey]: stateValue});
    }

    handleRangeChange([startDate, endDate]){
        this.setState({
            startDate: startDate,
            endDate: endDate
        });
    }

    render(){
        return (
            <div class="container">
                <h1>Logs</h1>

                <div class="form">

                    <FormGroup
                        className="form-item"
                        label="Servidores"
                        labelFor="empresa"
                    >
                        <select
                            id="empresa"
                            name="empresa"
                            class="select bp3-input"
                            onChange={this.handleSelectChange}
                        >
                            <option value="" disabled selected>Selecione uma Servidor</option>
                            {
                                this.state.servers.map( server => <option value={server.empresa}>{server.empresa}</option> )
                            }
                        </select>
                    </FormGroup>

                    <FormGroup
                        className="form-item"
                        label="Tipo"
                        labelFor="tipo"
                    >
                        <select
                            id="tipo"
                            name="tipo"
                            class="select bp3-input"
                            onChange={this.handleSelectChange}
                        >
                            <option value="" disabled selected>Selecione um tipo</option>
                            {
                                this.tipos.map( tipo => <option value={tipo}>{tipo}</option> )
                            }
                        </select>
                    </FormGroup>

                    <FormGroup
                        className="form-item"
                        label="Intervalo"
                        labelFor="interval"
                        labelInfo="em dias"
                    >
                        <DateRangeInput
                            id="interval"
                            name="interval"
                            formatDate={date => date.toLocaleString()}
                            onChange={this.handleRangeChange}
                            parseDate={str => new Date(str)}
                            value={[this.state.startDate, this.state.endDate]}
                        />
                    </FormGroup>
                </div>

            </div>
        );
    }
}

export default LogsPage;
