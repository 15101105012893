import React, {Component} from 'react';
import { Link, Route } from "react-router-dom";

import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import "./css/Developers.css";


import DevelopersAdicionar from "./Developers/Adicionar";
import Developer from '../models/Developer';

import Notify from '../entity/Notify';

class Developers extends Component {

    constructor(props){
        super(props);

        this.state = {
            developers: [],
            isAdicionarOpen: false
        }

        this.onAdicionarClicked = this.onAdicionarClicked.bind(this);
        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    onAdicionarClicked(){
        this.setState({ isAdicionarOpen: true });
    }

    onCloseDialog(){
        this.refreshDevs();
    }

    async onDelete(evt){
        const id = evt.target.closest("[objectid]").getAttribute('objectid');
        let model = await Developer.findById(id);
        await model.remove();
        await this.refreshDevs();
        Notify.success({message: "Removido com sucesso"});
    }

    async refreshDevs(){
        let devs = await Developer.findAll();
        this.setState({
            developers: devs
        });
    }

    componentDidMount(){
        this.refreshDevs();
    }

    render() {
        return (<div class="container">
            <h1>Developers</h1>
            <div class="page-buttons">
                <Link to="/devs/adicionar">
                    <Button onClick={this.onAdicionarClicked} intent={Intent.SUCCESS} text="Adicionar Dev" icon={IconNames.ADD} />
                </Link>
            </div>
            <div class="table">
                <table class="bp3-html-table bp3-condensed bp3-html-table-striped bp3-html-table-bordered bp3-interactive">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nome</th>
                            <th>Login</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.developers.map(dev =>
                            <tr>
                                <td>{dev._id}</td>
                                <td>{dev.nome}</td>
                                <td>{dev.login}</td>
                                <td class="buttons">
                                    <Link to={`/devs/${dev._id}/editar`}>
                                        <Button intent={Intent.SUCCESS} icon={IconNames.EDIT} />
                                    </Link>
                                    <Button objectid={dev._id} onClick={this.onDelete} intent={Intent.DANGER} icon={IconNames.REMOVE} />
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </div>

            <Route path="/devs/adicionar" render={() => <DevelopersAdicionar onCloseDialog={this.onCloseDialog} isOpen={this.state.isAdicionarOpen} />}  />
            <Route path="/devs/:id/editar" render={(props) => <DevelopersAdicionar onCloseDialog={this.onCloseDialog} objectid={props.match.params.id} isOpen /> } />


        </div>);
    }
}

export default Developers;
